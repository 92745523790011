import React from 'react';
import { Box, Dialog, Stack, useMediaQuery, Button, Typography, IconButton } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import { useAccount, useDisconnect } from 'wagmi';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { toast } from 'react-toastify';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

export default function ResponsiveDialog({ disconnectModal, toggleDisConnectModal }) {
    const theme = useTheme();
    const { disconnect } = useDisconnect();
    const { address, isConnected } = useAccount();
    const smallScreen = useMediaQuery(theme.breakpoints.down('xs'));

    return (
        <div>
            <Dialog
                fullScreen={smallScreen}
                fullWidth={true}
                open={disconnectModal}
                onClose={toggleDisConnectModal()}
                sx={{
                    '.MuiDialog-paperScrollPaper': {
                        borderRadius: '20px',
                        background: theme.palette.background.medium,
                    },
                }}
            >
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    px={4}
                    py={3}
                    sx={{
                        borderBottom: `1px solid ${theme.palette.background.borderLight}`,
                    }}
                >
                    <Box fontSize="22px" fontWeight={500}>
                        Your Wallet
                    </Box>
                    <Box
                        onClick={toggleDisConnectModal()}
                        display="flex"
                        alignItems="center"
                        p={0.5}
                        borderRadius="100px"
                        sx={{
                            cursor: 'pointer',
                            '&:hover': {
                                bgcolor: '#6C7DEB',
                            },
                        }}
                    >
                        <CloseIcon />
                    </Box>
                </Stack>
                <Stack
                    direction={{ sm: 'row', xs: 'column' }}
                    justifyContent="space-between"
                    alignItems={{ sm: 'center', xs: 'start' }}
                    gap={{ sm: 0, xs: 2 }}
                    py={2}
                    px={5}
                >
                    <Typography fontSize="22px" fontWeight={500}>
                        Address:
                    </Typography>
                    <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                        <Typography fontSize="18px" fontWeight={500}>
                            {isConnected && address.slice(0, 6) + '...' + address.slice(-6)}
                        </Typography>
                        <CopyToClipboard text={address} onCopy={() => toast.success('📋 Copied')}>
                            <IconButton>
                                <ContentCopyIcon />
                            </IconButton>
                        </CopyToClipboard>
                    </Box>
                </Stack>

                <Box mx="auto" my={2}>
                    <Button
                        variant="gradient"
                        sx={{
                            width: '200px',
                            height: '40px',
                        }}
                        onClick={() => {
                            disconnect();
                            toggleDisConnectModal();
                        }}
                    >
                        Disconnect
                    </Button>
                </Box>
            </Dialog>
        </div>
    );
}
