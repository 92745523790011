import React from "react";

import { WagmiConfig, createClient, configureChains } from "wagmi";

import { bsc, mainnet } from "wagmi/chains";

import { alchemyProvider } from "wagmi/providers/alchemy";
import { publicProvider } from "wagmi/providers/public";

import { CoinbaseWalletConnector } from "wagmi/connectors/coinbaseWallet";
import { InjectedConnector } from "wagmi/connectors/injected";
import { MetaMaskConnector } from "wagmi/connectors/metaMask";
import { WalletConnectConnector } from "wagmi/connectors/walletConnect";

import App from "./App";
import { AppContextProvider } from "./utils";

const { chains, provider, webSocketProvider } = configureChains(
	[bsc, mainnet],
	[
		alchemyProvider({ apiKey: "0fC4kOBJp5XgPZVIgS8dO4GbpyyMf16T" }),
		publicProvider(),
	]
);

const client = createClient({
	autoConnect: true,
	connectors: [
		new MetaMaskConnector({ chains }),
		new CoinbaseWalletConnector({
			chains,
			options: {
				appName: "wagmi",
			},
		}),
		new WalletConnectConnector({
			chains,
			options: {
				projectId: "adc44ed1cb9a9bdf0f9c5f85279b7c89",
			},
		}),
		new InjectedConnector({
			chains,
			options: {
				name: "Injected",
				shimDisconnect: true,
			},
		}),
	],
	provider,
	webSocketProvider,
});

function WagmiUtils() {
	return (
		<WagmiConfig client={client}>
			<AppContextProvider>
				<App />
			</AppContextProvider>
		</WagmiConfig>
	);
}

export default WagmiUtils;
