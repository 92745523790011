import React from "react";
import tokenAbi from "./tokenAbi.json";
import stakingAbi from "./stakingAbi.json";
import { tokenAddress, stakingAddress } from "./environment";
import { ethers } from "ethers";
import { Contract } from "ethers/lib";
let walletAddress = "0x8ba1f109551bD432803012645Ac136ddd64DBA72";

const provider = new ethers.providers.JsonRpcProvider(
	"https://bsc-dataseed.binance.org/"
);

export const voidAccount = new ethers.VoidSigner(walletAddress, provider);
function useContract(address, ABI, signer) {
	return React.useMemo(() => {
		if (signer) {
			return new Contract(address, ABI, signer);
		} else {
			return new Contract(address, ABI, voidAccount);
		}
	}, [address, ABI, signer]);
}

export function useTokenContract(signer) {
	return useContract(tokenAddress, tokenAbi, signer);
}

export function useStakingContract(signer) {
	return useContract(stakingAddress, stakingAbi, signer);
}
