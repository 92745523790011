import React, { useState, useContext, useEffect } from "react";
import { Box, Container, useMediaQuery, Grid, Button } from "@mui/material";
import waves from "../images/waves.png";
import section from "../images/section.png";
import bg from "../images/bg.png";
import moment from "moment";
import ArrowDownwardSharpIcon from "@mui/icons-material/ArrowDownwardSharp";
import { parseUnits } from "@ethersproject/units";
import { toast } from "react-toastify";
import Loading from "../loading";
import { useStakingContract, useTokenContract } from "../ConnectivityAss/hooks";
import { stakingAddress } from "../ConnectivityAss/environment";
import { AppContext, gasEstimationForAll } from "../utils";
import { useAccount } from "wagmi";

function Stake({ init, staked, unstaked, balance, Supply }) {
	const { toggleConnectModal, signer } = useContext(AppContext);
	const { address: account } = useAccount();
	const matches = useMediaQuery("(max-width:600px)");
	const StakingContract = useStakingContract(signer);
	const tokenContract = useTokenContract(signer);
	const [index, setindex] = useState(0);
	const [amount, setamount] = useState("");
	const [loading, setloading] = useState(false);
	const [bonus, setbonus] = useState(0);
	const [lockState, setLockState] = useState(1);
	const StakingContract1 = useStakingContract();
	const [apy, setApy] = useState();
	const unlockIndex = 3;

	const unlockStakeHandler = async () => {
		const dec = +(await tokenContract.decimals());
		if (!account) {
			toast.error("Error! Please connect your wallet.");
		} else if (!amount) {
			toast.error("Error! Please enter amount.");
		} else {
			try {
				setloading(true);
				const allowance = await tokenContract.allowance(
					account,
					stakingAddress
				);
				if (+allowance === 0) {
					const balance = await tokenContract.balanceOf(account);
					console.log(balance, "fcghhhhhhhhhf");
					const data = [stakingAddress, balance.toString()];
					const fn = tokenContract.estimateGas.approve;
					const tx = await tokenContract.approve(...data, {
						gasLimit: gasEstimationForAll(account, fn, data),
					});
					await tx.wait();
				} else {
					if (+allowance > +parseUnits(amount, dec)) {
						console.log("greater");
					} else {
						const data = [stakingAddress, balance.toString()];
						const fn = tokenContract.estimateGas.approve;
						const tx = await tokenContract.approve(...data, {
							gasLimit: gasEstimationForAll(account, fn, data),
						});
						await tx.wait();
					}
				}
				const fn = StakingContract.estimateGas.stake;
				const data = [parseUnits(amount, dec), unlockIndex.toString()];
				const tx1 = await StakingContract.stake(...data, {
					gasLimit: gasEstimationForAll(account, fn, data),
				});
				await tx1.wait();
				toast.success("Success! Transaction Confirmed.");
				setloading(false);
				init();
			} catch (err) {
				console.log(err);
				if (err?.data?.message) {
					toast.error(err?.data?.message);
				} else if (err?.reason) {
					toast.error(err?.reason);
				} else {
					toast.error(err?.message);
				}
				setloading(false);
			}
		}
	};

	const stakeHandler = async () => {
		const dec = +(await tokenContract.decimals());
		console.log("tokencontract");
		if (!account) {
			toast.error("Error! Please connect your wallet.");
		} else if (!amount) {
			toast.error("Error! Please enter amount.");
		} else {
			try {
				setloading(true);
				const allowance = await tokenContract.allowance(
					account,
					stakingAddress
				);
				console.log(allowance, "allowance");
				if (+allowance === 0) {
					const balance = await tokenContract.balanceOf(account);
					const fn = tokenContract.estimateGas.approve;
					const data = [stakingAddress, balance.toString()];
					const tx = await tokenContract.approve(...data, {
						gasLimit: gasEstimationForAll(account, fn, data),
					});
					await tx.wait();
				} else {
					if (+allowance > +parseUnits(amount, dec)) {
						console.log("greater");
					} else {
						const fn = tokenContract.estimateGas.approve;
						const data = [stakingAddress, balance.toString()];
						const tx = await tokenContract.approve(...data, {
							gasLimit: gasEstimationForAll(account, fn, data),
						});
						await tx.wait();
					}
				}
				console.log("staking");

				const fn = StakingContract.estimateGas.stake;
				const data = [parseUnits(amount, dec), index.toString()];
				const tx1 = await StakingContract.stake(...data, {
					gasLimit: gasEstimationForAll(account, fn, data),
				});
				await tx1.wait();
				toast.success("Success! Transaction Confirmed.");
				setloading(false);
				init();
			} catch (err) {
				console.log(err);

				if (err?.data?.message) {
					toast.error(err?.data?.message);
				} else if (err?.reason) {
					toast.error(err?.reason);
				} else {
					toast.error(err?.message);
				}
				setloading(false);
			}
		}
	};
	useEffect(() => {
		const init1 = async () => {
			try {
				const apr = await StakingContract1.Bonus(unlockIndex.toString());
				console.log(+apr / 10, "apy");
				setApy(+apr / 10);
			} catch (error) {
				console.log(error);
			}
		};
		const init = async () => {
			try {
				const percent = await StakingContract1.percentDivider();
				const bonus = await StakingContract1.Bonus(index.toString());
				setbonus(+amount + (+bonus / +percent) * +amount);
				const apr = await StakingContract1.Bonus(unlockIndex.toString());
				setApy(+apr / 10);
			} catch (error) {
				console.log(error);
			}
		};
		if (StakingContract1 && +amount > 0) {
			init();
		}
		if (StakingContract1) {
			init1();
		}
	}, [StakingContract1, amount, index]);
	return (
		<Box pt={17} id="calculator">
			<Loading loading={loading} />

			<img
				src={waves}
				alt=""
				style={{
					position: "absolute",
					top: matches ? "110%" : "78%",
					width: matches ? "100%" : "100%",
					// left: matches ? "0px" : "-94px",
				}}
			/>
			<Box
				fontSize={{ xs: "25px", sm: "30px" }}
				fontWeight={700}
				textAlign="center"
				mt={{ xs: 2, sm: 10 }}
				px={3}
			>
				STAKE TOKENS TO EARN MORE
			</Box>
			<Box
				fontSize={{ xs: "12px", sm: "15px" }}
				textAlign="center"
				fontWeight={400}
				px={3}
			>
				Adadao staking program rewards token holders with generous token
				rewards.
			</Box>
			<Box
				sx={{
					background: `url(${bg})`,
					backgroundRepeat: "no-repeat",
					backgroundPosition: "right",
					position: "relative",
				}}
				pt={5}
				mt={{ xs: 5, sm: 20 }}
			>
				<Container maxWidth="lg">
					<Grid
						container
						spacing={{ xs: 0, sm: 4 }}
						justifyContent={{ xs: "center", sm: "space-between" }}
					>
						<Grid
							items
							xs={12}
							sm={12}
							md={5}
							display="flex"
							justifyContent="center"
							alignItems="center"
						>
							<Box
								width="100%"
								display="flex"
								alignItems="center"
								flexDirection="column"
								px={4}
								py={7}
								style={{
									backgroundImage: `url(${section})`,

									backgroundRepeat: "no-repeat",
									backgroundSize: "100% 100%",
									backgroundPosition: "center",
								}}
							>
								<Box display="flex" my={2}>
									<Button
										sx={{
											background:
												lockState === 1
													? "linear-gradient(345deg, #120A57 0%, #0066FF 75%)"
													: "linear-gradient(345deg, #0066FF 0%, #120A57 100%)",
											border: "1px solid #0066FF",
											color: "#fff",
											padding: "10px 40px",
											fontSize: matches ? "12px" : "14px",
											borderRadius: "10px",
											cursor: "pointer",
											height: "44px",
											marginRight: "15px",
											textTransform: "capitalize",
											boxShadow:
												lockState === 1
													? "1px 1px 6px 1px #fff"
													: "-1px 3px 3px rgba(1, 100, 246, 0.29)",
										}}
										onClick={() => setLockState(1)}
									>
										Locked
									</Button>
									<Button
										sx={{
											background:
												lockState === 2
													? "linear-gradient(345deg, #120A57 0%, #0066FF 75%)"
													: "linear-gradient(345deg, #0066FF 0%, #120A57 100%)",
											boxShadow:
												lockState === 2
													? "1px 1px 6px 1px #fff"
													: "-1px 3px 3px rgba(1, 100, 246, 0.29)",
											border: "1px solid #0066FF",
											color: "#fff",
											padding: "10px 40px",
											fontSize: matches ? "12px" : "14px",
											borderRadius: "10px",
											cursor: "pointer",
											height: "44px",
											textTransform: "capitalize",
										}}
										onClick={() => setLockState(2)}
									>
										UnLocked
									</Button>
								</Box>
								{lockState === 1 ? (
									<>
										<Box
											fontSize={{ xs: "12px", sm: "16px" }}
											fontWeight="400"
											textAlign="center"
											width={{ xs: "100%", sm: "80%" }}
											// mx="auto"
											my={0}
											mt={2}
										>
											Calculate your ADADAO depending on the amount of staked
											tokens and your lock time.
										</Box>

										<Box
											width={{ xs: "100%", sm: "70%" }}
											mx="auto"
											mt={1}
											p={0.2}
										>
											<Box display="flex" alignItems="center" p={0.5}>
												<Box width="40%" p={1}>
													<Box
														fontFamily="Roboto"
														fontWeight={900}
														textAlign="center"
														fontSize={{ xs: "14px", sm: "20px" }}
													>
														ADAO
													</Box>
												</Box>
												<Box
													border="1px solid #fff"
													height="45px"
													width="60%"
													p={1.5}
												>
													<input
														type="text"
														name=""
														placeholder="5000"
														id=""
														style={{
															background: "transparent",
															border: "none",
															outline: "none",
															height: "20px",
															color: "#fff",
															textAlign: "center",
															fontSize: "15px",
															fontWeight: 400,
															width: "100%",
														}}
														value={amount}
														onChange={(e) => setamount(e.target.value)}
													/>
												</Box>
											</Box>
										</Box>
										<Box
											align="center"
											fontFamily="Roboto"
											fontWeight={400}
											fontSize={{ xs: "20px", sm: "30px" }}
											my={0}
										>
											+
										</Box>
										<Box
											fontFamily="Roboto"
											fontWeight={400}
											textAlign="center"
											fontSize={{ xs: "12px", sm: "16px" }}
											my={0}
										>
											Lock tokens for Days
										</Box>
										<Box
											width="100%"
											display="flex"
											alignItems="center"
											justifyContent={{
												xs: "space-evenly",
												sm: "space-evenly",
											}}
											p={1}
											my={0}
											mt={2}
											flexWrap="wrap"
										>
											{/* <Box
                        fontWeight={400}
                        boxShadow=" 0px 0px 21px rgba(0, 0, 0, 0.08)"
                        bgcolor={
                          index === 0 ? "#120A57" : "rgba(255, 255, 255, 0.12)"
                        }
                        p={1}
                        style={{ cursor: "pointer" }}
                        onClick={() => setindex(0)}
                        width="67px"
                        height="40px"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        mt={{ xs: 1, sm: 0 }}
                      >
                        30
                      </Box>
                      <Box
                        fontWeight={400}
                        boxShadow=" 0px 0px 21px rgba(0, 0, 0, 0.08)"
                        bgcolor={
                          index === 1 ? "#120A57" : "rgba(255, 255, 255, 0.12)"
                        }
                        p={1}
                        style={{ cursor: "pointer" }}
                        onClick={() => setindex(1)}
                        width="67px"
                        height="40px"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        mt={{ xs: 1, sm: 0 }}
                      >
                        60
                      </Box> */}
											<Box
												fontWeight={400}
												boxShadow=" 0px 0px 21px rgba(0, 0, 0, 0.08)"
												bgcolor={
													index === 0 ? "#120A57" : "rgba(255, 255, 255, 0.12)"
												}
												p={1}
												style={{ cursor: "pointer" }}
												onClick={() => setindex(0)}
												width="67px"
												height="40px"
												display="flex"
												alignItems="center"
												justifyContent="center"
												mt={{ xs: 1, sm: 0 }}
											>
												90
											</Box>
											<Box
												fontWeight={400}
												boxShadow=" 0px 0px 21px rgba(0, 0, 0, 0.08)"
												bgcolor={
													index === 1 ? "#120A57" : "rgba(255, 255, 255, 0.12)"
												}
												p={1}
												style={{ cursor: "pointer" }}
												onClick={() => setindex(1)}
												width="67px"
												height="40px"
												display="flex"
												alignItems="center"
												justifyContent="center"
												mt={{ xs: 1, sm: 0 }}
											>
												180
											</Box>
											<Box
												fontWeight={400}
												boxShadow=" 0px 0px 21px rgba(0, 0, 0, 0.08)"
												bgcolor={
													index === 2 ? "#120A57" : "rgba(255, 255, 255, 0.12)"
												}
												p={1}
												style={{ cursor: "pointer" }}
												onClick={() => setindex(2)}
												width="67px"
												height="40px"
												display="flex"
												alignItems="center"
												justifyContent="center"
												mt={{ xs: 1, sm: 0 }}
											>
												365
											</Box>
										</Box>

										<Box
											align="center"
											fontFamily="Roboto"
											fontWeight={400}
											fontSize={{ xs: "20px", sm: "30px" }}
											my={0}
										>
											+
										</Box>

										<Box
											component="h3"
											fontWeight={400}
											fontSize={{ xs: "20", sm: "25px" }}
											textAlign="center"
											my={0}
											bgcolor="transparent"
											mx="auto"
											width="140px"
											border="1px solid white"
											borderRadius="5px"
											p={1}
										>
											{index === 0 ? "20" : index === 1 ? "25" : "30"}%{" "}
											<span style={{ fontSize: "14px" }}>APY </span>
										</Box>
										<Box align="center" my={1}>
											<ArrowDownwardSharpIcon
												sx={{
													width: "30px",
												}}
											/>
										</Box>

										<Box
											width={{ xs: "100%", sm: "70%" }}
											mx="auto"
											p={0.2}
											my={0}
										>
											<Box display="flex" alignItems="center" p={0.5}>
												<Box width="40%" p={1}>
													<Box
														fontFamily="Roboto"
														fontWeight={900}
														textAlign="center"
														fontSize={{ xs: "14px", sm: "20px" }}
													>
														ADAO
													</Box>
												</Box>
												<Box
													border="1px solid #fff"
													height="45px"
													width="60%"
													p={1.5}
												>
													<input
														type="text"
														name=""
														placeholder="5000"
														id=""
														style={{
															background: "transparent",
															border: "none",
															outline: "none",
															height: "20px",
															color: "#fff",
															textAlign: "center",
															fontSize: "15px",
															fontWeight: 400,
															width: "100%",
														}}
														value={bonus}
														readOnly
													/>
												</Box>
											</Box>
										</Box>

										<Box
											fontSize={{ xs: "10px", sm: "15px" }}
											textAlign="center"
											color="#fff"
											p={0}
											fontWeight="400"
											mt={2}
											mb={5}
										>
											locked until{" "}
											{moment()
												.add(index === 0 ? 90 : index === 1 ? 180 : 365, "days")
												.format("MM/DD/YYYY hh:mma")}
										</Box>
										<Box align="center" width="100%" my={2}>
											{account ? (
												<button
													style={{
														background:
															"linear-gradient(180deg, #0066FF 0%, #120A57 100%)",
														boxShadow: "-1px 3px 3px rgba(1, 100, 246, 0.29)",
														border: "none",
														color: "#fff",
														padding: "10px 40px",
														fontSize: matches ? "15px" : "20px",
														borderRadius: "10px",
														width: "100%",
														cursor: "pointer",
														height: "77px",
													}}
													onClick={stakeHandler}
												>
													Stake
												</button>
											) : (
												<button
													style={{
														background:
															"linear-gradient(180deg, #0066FF 0%, #120A57 100%)",
														boxShadow: "-1px 3px 3px rgba(1, 100, 246, 0.29)",
														border: "none",
														color: "#fff",
														padding: "10px 40px",
														fontSize: matches ? "15px" : "20px",
														borderRadius: "10px",
														width: "100%",
														cursor: "pointer",
														height: "55px",
													}}
													onClick={toggleConnectModal()}
												>
													Connect Wallet
												</button>
											)}
										</Box>
									</>
								) : (
									<Box>
										<Box my={5} p={0.2}>
											<Box display="flex" alignItems="center" p={0.5}>
												<Box width="40%" p={1}>
													<Box
														fontFamily="Roboto"
														fontWeight={900}
														textAlign="center"
														fontSize={{ xs: "14px", sm: "20px" }}
													>
														ADAO
													</Box>
												</Box>
												<Box
													border="1px solid #fff"
													height="45px"
													width="60%"
													p={1.5}
												>
													<input
														type="text"
														name=""
														placeholder="5000"
														id=""
														style={{
															background: "transparent",
															border: "none",
															outline: "none",
															height: "20px",
															color: "#fff",
															textAlign: "center",
															fontSize: "15px",
															fontWeight: 400,
															width: "100%",
														}}
														value={amount}
														onChange={(e) => setamount(e.target.value)}
													/>
												</Box>
											</Box>
											<Box
												textAlign="center"
												fontFamily="Roboto"
												fontWeight={400}
												fontSize={{ xs: "30px", sm: "44px" }}
												my={0}
											>
												+
											</Box>
											<Box
												component="h3"
												fontWeight={400}
												fontSize={{ xs: "20", sm: "30px" }}
												textAlign="center"
												my={0}
												bgcolor="transparent"
												mx="auto"
												width="140px"
												border="2px solid white"
												borderRadius="5px"
												p={1}
											>
												{apy === 3 ? apy : 15}%{" "}
												<span style={{ fontSize: "14px" }}>APY </span>
											</Box>
										</Box>

										<Box align="center" width="100%" my={2}>
											{account ? (
												<button
													style={{
														background:
															"linear-gradient(180deg, #0066FF 0%, #120A57 100%)",
														boxShadow: "-1px 3px 3px rgba(1, 100, 246, 0.29)",
														border: "none",
														color: "#fff",
														padding: "10px 40px",
														fontSize: matches ? "15px" : "20px",
														borderRadius: "10px",
														width: "100%",
														cursor: "pointer",
														height: "77px",
													}}
													onClick={unlockStakeHandler}
												>
													Stake
												</button>
											) : (
												<button
													style={{
														background:
															"linear-gradient(180deg, #0066FF 0%, #120A57 100%)",
														boxShadow: "-1px 3px 3px rgba(1, 100, 246, 0.29)",
														border: "none",
														color: "#fff",
														padding: "10px 40px",
														fontSize: matches ? "15px" : "20px",
														borderRadius: "10px",
														width: "100%",
														cursor: "pointer",
														height: "55px",
													}}
													onClick={toggleConnectModal()}
												>
													Connect Wallet
												</button>
											)}
										</Box>
									</Box>
								)}
							</Box>
						</Grid>
						<Grid
							items
							xs={12}
							sm={12}
							md={5}
							// pt={account ? 0 : 10}
							display="flex"
							justifyContent={{ xs: "center", sm: "flex-start" }}
						>
							<Box mt={account ? 0 : 4} width="100%">
								{account ? (
									<Box
										display="flex"
										flexDirection="column"
										px={5}
										pt={5}
										pb={{ xs: 5 }}
										style={{
											backgroundImage: `url(${section})`,
											backgroundRepeat: "no-repeat",
											backgroundSize: "100% 100%",
											backgroundPosition: "center",
										}}
									>
										<Box display="flex" justifyContent="space-between">
											<Box
												my={2}
												fontSize={{ xs: "20px", sm: "25px" }}
												fontWeight={700}
											>
												Your balance
											</Box>
											<Box
												my={2}
												fontSize={{ xs: "20px", sm: "25px" }}
												fontWeight={700}
											>
												{parseInt(balance).toLocaleString()}
											</Box>
										</Box>
										<Box
											height="40px"
											border="1px solid #D8D8D8"
											boxShadow="0px 4px 8px 2px #120A57"
											borderRadius="3px"
											display="flex"
											justifyContent="space-between"
											alignItems="center"
											width="100%"
											px={2}
										>
											<Box
												fontSize={{ xs: "12px", sm: "15px" }}
												fontWeight={400}
											>
												You have staked
											</Box>
											<Box
												fontSize={{ xs: "12px", sm: "15px" }}
												fontWeight={700}
											>
												{(+staked).toFixed(0) - (+unstaked).toFixed(0)} ADAO
											</Box>
										</Box>

										<Box
											display="flex"
											justifyContent="space-between"
											mb={2}
											mt={5}
										>
											<Box
												fontSize={{ xs: "12px", sm: "15px" }}
												fontWeight={700}
											>
												Token Name
											</Box>
											<Box
												fontSize={{ xs: "10px", sm: "13px" }}
												fontWeight={500}
											>
												ADAO
											</Box>
										</Box>

										<Box display="flex" justifyContent="space-between" my={2}>
											<Box
												fontSize={{ xs: "12px", sm: "15px" }}
												fontWeight={700}
											>
												Token Decimals
											</Box>
											<Box
												fontSize={{ xs: "10px", sm: "13px" }}
												fontWeight={500}
											>
												9
											</Box>
										</Box>

										<Box display="flex" justifyContent="space-between" my={2}>
											<Box
												fontSize={{ xs: "12px", sm: "15px" }}
												fontWeight={700}
											>
												Total Supply
											</Box>
											<Box
												fontSize={{ xs: "10px", sm: "13px" }}
												fontWeight={500}
											>
												{/* 1 Trillion */}
												{Supply}
											</Box>
										</Box>

										<Box display="flex" justifyContent="space-between" my={2}>
											<Box
												fontSize={{ xs: "12px", sm: "15px" }}
												fontWeight={700}
											>
												{/* Closing Date */}
											</Box>
											<Box
												fontSize={{ xs: "10px", sm: "13px" }}
												fontWeight={500}
											>
												{/* Fri,24 jun 2022 08:00;42 GMT */}
											</Box>
										</Box>

										<Box display="flex" justifyContent="space-between" my={2}>
											<Box
												fontSize={{ xs: "12px", sm: "15px" }}
												fontWeight={700}
											>
												{/* Maturity */}
											</Box>
											<Box
												fontSize={{ xs: "10px", sm: "13px" }}
												fontWeight={500}
											>
												{/* Open */}
											</Box>
										</Box>

										{/* <Box
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      position="relative"
                      my={4}
                    >
                      <img src={btn} width="100%" alt="" />
                      <Box position="absolute">Withdraw</Box>
                    </Box> */}
									</Box>
								) : (
									<>
										<Box fontSize={{ xs: "20px", sm: "25px" }} fontWeight={700}>
											Your balance
										</Box>
										<Box
											fontSize={{ xs: "12px", sm: "15px" }}
											fontWeight={400}
											mb={3}
										>
											Please connect to wallet and switch to Binance Smart Chain
											network
										</Box>

										<Button
											fullWidth
											variant="outlined"
											sx={{
												border: "1px solid #D8D8D8",
												color: "#fff",
												textTransform: "capitalize",
												height: "53px",
												borderRadius: "3px",
											}}
											onClick={toggleConnectModal()}
										>
											Connect Wallet
										</Button>
									</>
								)}
							</Box>
						</Grid>
					</Grid>
				</Container>
			</Box>
		</Box>
	);
}

export default Stake;
