import { BigNumber, MaxUint256 } from "ethers/lib";
import React from "react";
import { createContext } from "react";
import { useState } from "react";
import { useSigner } from "wagmi";

export function calculateGasMargin(value) {
	return +(
		(value * BigNumber.from(10000).add(BigNumber.from(1000))) /
		BigNumber.from(10000)
	).toFixed(0);
}
//CalculatePayableGas
export const gasEstimationPayable = async (account, fn, data, amount) => {
	if (account) {
		const estimateGas = await fn(...data, MaxUint256).catch(() => {
			return fn(...data, { value: amount.toString() });
		});
		return calculateGasMargin(estimateGas);
	}
};
export const gasEstimationForAll = async (account, fn, data) => {
	if (account) {
		const estimateGas = await fn(...data, MaxUint256).catch(() => {
			return fn(...data);
		});
		return calculateGasMargin(estimateGas);
	}
};

export const AppContext = createContext();
export const AppContextProvider = ({ children }) => {
	const { data } = useSigner();

	const [connectModal, setConnectModal] = useState(false);
	const [disconnectModal, setDisconnectModal] = useState(false);

	const toggleConnectModal = () => () => {
		setConnectModal(!connectModal);
	};

	const toggleDisConnectModal = () => () => {
		setDisconnectModal(!disconnectModal);
	};

	return (
		<AppContext.Provider
			value={{
				signer: data,
				connectModal,
				setConnectModal,
				disconnectModal,
				setDisconnectModal,
				toggleDisConnectModal,
				toggleConnectModal,
			}}
		>
			{children}
		</AppContext.Provider>
	);
};
