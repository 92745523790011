import { Container, Box, useMediaQuery } from "@mui/material";
import moment from "moment";
import React, { useState, useContext } from "react";
import { toast } from "react-toastify";
import { useStakingContract } from "../ConnectivityAss/hooks";
import Loading from "../loading";
import logo from "../images/logo.png";
import { AppContext, gasEstimationForAll } from "../utils";
import UnstakeModal from "./unstakeModal";
import { useAccount } from "wagmi";

function Details({ init, stakeDetails }) {
	const { address: account } = useAccount();
	const { signer } = useContext(AppContext);
	const matches = useMediaQuery("(max-width:900px");
	const [open, setopen] = useState(false);
	const [index, setindex] = useState("0");
	const StakingContract = useStakingContract(signer);
	const [loading, setloading] = useState(false);
	const unstakeHandler = async () => {
		try {
			setloading(true);
			const fn = StakingContract.estimateGas.unstake;
			const data = [index.toString()];
			const tx = await StakingContract.unstake(...data, {
				gasLimit: gasEstimationForAll(account, fn, data),
			});
			await tx.wait();
			toast.success("Success! Amount successfully unsataked.");
			init();
			setopen(false);
			setloading(false);
		} catch (error) {
			if (error?.data?.message) {
				toast.error(error?.data?.message);
			} else if (error?.reason) {
				toast.error(error?.reason);
			} else {
				toast.error(error?.message);
			}
			setopen(false);
			setloading(false);
		}
	};

	const claimHandler = async (index) => {
		try {
			setloading(true);
			const fn = StakingContract.estimateGas.stakersRecord;
			const data = [account, index.toString()];
			const record = await StakingContract.stakersRecord(...data, {
				gasLimit: gasEstimationForAll(account, fn, data),
			});
			// console.log(+record.plan, "record");
			if (+record.plan === 3) {
				const fn = StakingContract.estimateGas.withdrawUnlocked;
				const data = [index.toString()];
				const tx = await StakingContract.withdrawUnlocked(index.toString(), {
					gasLimit: gasEstimationForAll(account, fn, data),
				});
				await tx.wait();
				toast.success("Success! Amount successfully claimed.");
				init();
				setloading(false);
			} else {
				const fn = StakingContract.estimateGas.withdraw;
				const data = [index.toString()];
				const tx = await StakingContract.withdraw(index.toString(), {
					gasLimit: gasEstimationForAll(account, fn, data),
				});
				await tx.wait();
				toast.success("Success! Amount successfully claimed.");
				init();
				setloading(false);
			}
		} catch (error) {
			if (error?.data?.message) {
				toast.error(error?.data?.message);
			} else if (error?.reason) {
				toast.error(error?.reason);
			} else {
				toast.error(error?.message);
			}
			setloading(false);
		}
	};

	const reStakeHandler = async (index) => {
		try {
			setloading(true);
			const fn = StakingContract.estimateGas.reStakeReward;
			const data = [index.toString()];
			const tx = await StakingContract.reStakeReward(...data, {
				gasLimit: gasEstimationForAll(account, fn, data),
			});
			await tx.wait();
			toast.success("Success! Amount successfully restaked.");
			init();
			setloading(false);
		} catch (error) {
			if (error?.data?.message) {
				toast.error(error?.data?.message);
			} else if (error?.reason) {
				toast.error(error?.reason);
			} else {
				toast.error(error?.message);
			}
			setloading(false);
		}
	};

	return (
		<>
			<Loading loading={loading} />
			<UnstakeModal
				open={open}
				loading={loading}
				setOpen={setopen}
				unstakeHandler={unstakeHandler}
			/>
			<Box
				// style={{
				//   background: `url(${bg})`,
				//   backgroundRepeat: "no-repeat",
				//   backgroundSize: "cover",
				//   backgroundPosition: "100 ,100",
				// }}
				// height={{ xs: "auto", sm: "834px" }}
				mt={15}
				display="flex"
				justifyContent="center"
				alignItems="center"
				position="relative"
				pb={25}
			>
				<Container maxWidth="lg">
					<Box
						fontSize={{ xs: "25px", sm: "30px" }}
						fontWeight={700}
						textAlign="center"
						mt={{ xs: 2, sm: 10 }}
						px={3}
					>
						<img src={logo} alt="" />
					</Box>
					<Box display="flex" justifyContent="center" width="100%" pt={3}>
						<Box
							width={matches ? "100%" : "900px"}
							// height={{ xs: "auto", sm: "308px" }}
							sx={{
								background: "linear-gradient(180deg, #0066FF 0%, #120A57 100%)",
								boxShadow: "-1px 3px 3px rgba(1, 100, 246, 0.29)",
							}}
							borderRadius="8px"
							position="relative"
							top={{ xs: "70px", sm: "80px" }}
							style={{
								backgroundBlendMode: "Pass through",
								backdropFilter: "blur(239px)",
							}}
							p={{ xs: 1.5, sm: 6 }}
						>
							<Box
								bgcolor="rgba(0, 0, 0, 0.1)"
								borderRadius="8px"
								style={{
									backgroundBlendMode: "Pass through",
									backdropFilter: "blur(26px)",
								}}
								display="flex"
								flexDirection="column"
								justifyContent="space-between"
							>
								<Box display="flex" alignItems="center" height="60px">
									<Box width="20%" p={{ xs: 1, sm: 2 }}>
										<Box
											fontSize={{ xs: "10px", sm: "20px" }}
											fontWeight={400}
											textAlign="center"
										>
											Staked Amount
										</Box>
									</Box>
									<Box width="20%" p={{ xs: 1, sm: 2 }}>
										<Box
											fontSize={{ xs: "10px", sm: "20px" }}
											fontWeight={400}
											textAlign="center"
										>
											Withdrawal Time
										</Box>
									</Box>

									<Box width="20%" p={{ xs: 1, sm: 2 }}>
										<Box
											fontSize={{ xs: "10px", sm: "20px" }}
											fontWeight={400}
											textAlign="center"
										>
											UnStake
										</Box>
									</Box>
									<Box
										fontSize={{ xs: "10px", sm: "20px" }}
										width="20%"
										p={{ xs: 1, sm: 2 }}
									>
										<Box fontWeight={400} textAlign="center">
											Claim
										</Box>
									</Box>
									<Box
										fontSize={{ xs: "10px", sm: "20px" }}
										width="20%"
										p={{ xs: 1, sm: 2 }}
									>
										<Box fontWeight={400} textAlign="center">
											ReStake
										</Box>
									</Box>
								</Box>
								{stakeDetails?.length !== 0 ? (
									stakeDetails.map(
										({ time, amount, unstaked, withdrawan, plan }, index) => (
											<Box
												width="100%"
												minHeight="45px"
												// bgcolor="rgba(0, 0, 0, 0.4)"
												borderRadius="8px"
												style={{
													backgroundBlendMode: "Pass through",
													backdropFilter: "blur(26px)",
												}}
												key={index}
											>
												<Box
													display="flex"
													bgcolor="rgba(255, 255, 255, 0.05)"
													alignItems="center"
													justifyContent="space-around"
													//   p={{ xs: 1, sm: 2 }}
													fontSize={{ xs: "10px", sm: "18px" }}
												>
													<Box width="20%" p={{ sm: 2 }}>
														<Box fontWeight={400} textAlign="center">
															{(+amount).toFixed(0)}
														</Box>
													</Box>

													<Box width="20%" p={{ sm: 2 }}>
														<Box fontWeight={400} textAlign="center">
															{plan === 3
																? "Claim anytime"
																: moment.unix(+time).format("llll")}
														</Box>
													</Box>

													<Box width="20%" p={{ sm: 2 }}>
														<Box
															fontWeight={400}
															textAlign="center"
															display={plan === 3 ? "none" : "block"}
														>
															<button
																disabled={
																	+time < +moment().format("x") / 1000 ||
																	withdrawan ||
																	unstaked
																		? true
																		: false
																}
																style={{
																	background:
																		"linear-gradient(180deg, #0066FF 0%, #120A57 100%)",
																	boxShadow:
																		"-1px 3px 3px rgba(1, 100, 246, 0.29)",
																	border: "none",
																	fontWeight: "400",
																	borderRadius: "12px",
																	height: matches ? "35px" : "42px",
																	textTransform: "capitalize",
																	color:
																		+time < +moment().format("x") / 1000 ||
																		withdrawan ||
																		unstaked
																			? "gray"
																			: "white",
																	padding: matches ? "10px 7px" : "10px 30px",
																	fontSize: matches ? "10px" : "18px",
																	marginRight: matches ? "2px" : "10px",
																	cursor:
																		+time < +moment().format("x") / 1000 ||
																		withdrawan ||
																		unstaked
																			? "no-drop"
																			: "pointer",
																}}
																onClick={() => {
																	if (plan === 3) {
																		setopen(false);
																		unstakeHandler(index);
																	} else {
																		setindex(index);
																		setopen(true);
																	}
																}}
															>
																UnStake
															</button>
														</Box>
													</Box>

													<Box width="20%" p={{ sm: 2 }}>
														<Box fontWeight={400} textAlign="center">
															<button
																disabled={
																	(+time > +moment().format("x") / 1000 ||
																		withdrawan ||
																		unstaked) &&
																	plan !== 3
																		? true
																		: false
																}
																style={{
																	width: "fit-content",
																	background:
																		"linear-gradient(180deg, #0066FF 0%, #120A57 100%)",
																	boxShadow:
																		"-1px 3px 3px rgba(1, 100, 246, 0.29)",
																	fontWeight: "400",
																	borderRadius: "12px",
																	height: matches ? "35px" : "42px",
																	border: "none",
																	textTransform: "capitalize",
																	color:
																		(+time > +moment().format("x") / 1000 ||
																			withdrawan ||
																			unstaked) &&
																		plan !== 3
																			? "gray"
																			: "white",
																	padding: matches ? "10px 10px" : "10px 30px",
																	fontSize: matches ? "10px" : "16px",

																	cursor:
																		(+time > +moment().format("x") / 1000 ||
																			withdrawan ||
																			unstaked) &&
																		plan !== 3
																			? "no-drop"
																			: "pointer",
																}}
																onClick={() => claimHandler(index)}
															>
																Claim
															</button>
														</Box>
													</Box>

													<Box width="20%" p={{ sm: 2 }}>
														<Box fontWeight={400} textAlign="center">
															{plan === 3 ? (
																<button
																	disabled={
																		withdrawan || unstaked ? true : false
																	}
																	style={{
																		background:
																			"linear-gradient(180deg, #0066FF 0%, #120A57 100%)",
																		boxShadow:
																			"-1px 3px 3px rgba(1, 100, 246, 0.29)",

																		fontWeight: "400",
																		borderRadius: "12px",
																		height: matches ? "35px" : "42px",
																		border: "none",
																		textTransform: "capitalize",
																		color: unstaked ? "gray" : "white",
																		padding: matches ? "10px 7px" : "10px 30px",
																		fontSize: matches ? "10px" : "18px",

																		cursor: unstaked ? "no-drop" : "pointer",
																	}}
																	onClick={() => reStakeHandler(index)}
																>
																	ReStake
																</button>
															) : (
																<button
																	disabled={
																		+time > +moment().format("x") / 1000 ||
																		withdrawan ||
																		unstaked
																			? true
																			: false
																	}
																	style={{
																		background:
																			"linear-gradient(180deg, #0066FF 0%, #120A57 100%)",
																		boxShadow:
																			"-1px 3px 3px rgba(1, 100, 246, 0.29)",
																		display:
																			+time > +moment().format("x") / 1000
																				? "none"
																				: "block",
																		fontWeight: "400",
																		borderRadius: "12px",
																		height: matches ? "35px" : "42px",
																		border: "none",
																		textTransform: "capitalize",
																		color:
																			+time > +moment().format("x") / 1000 ||
																			withdrawan ||
																			unstaked
																				? "gray"
																				: "white",
																		padding: matches ? "10px 7px" : "10px 30px",
																		fontSize: matches ? "10px" : "18px",

																		cursor:
																			+time > +moment().format("x") / 1000 ||
																			withdrawan ||
																			unstaked
																				? "no-drop"
																				: "pointer",
																	}}
																	onClick={() => reStakeHandler(index)}
																>
																	ReStake
																</button>
															)}
														</Box>
													</Box>
												</Box>
											</Box>
										)
									)
								) : (
									<Box
										width="100%"
										minHeight="45px"
										bgcolor="rgba(255, 255, 255, 0.12)"
										borderRadius="8px"
										style={{
											backgroundBlendMode: "Pass through",
											backdropFilter: "blur(26px)",
										}}
										display="flex"
										// bgcolor="rgba(255, 255, 255, 0.05)"
										alignItems="center"
										justifyContent="space-around"
									>
										<Box
											fontSize={{ xs: "10px", sm: "16px" }}
											fontWeight={400}
											textAlign="center"
										>
											You have no stake record yet.
										</Box>
									</Box>
								)}
							</Box>
						</Box>
					</Box>
				</Container>
			</Box>
		</>
	);
}

export default Details;
