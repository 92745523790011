import React from "react";
import { Box, Button, Container, Grid, useMediaQuery } from "@mui/material";
import { Link } from "react-scroll";
import bg from "../images/main-bg.png";
import underline from "../images/underline.png";
import sundaeSwap from "../images/sundaeSwap.png";
import mexcGlobal from "../images/mexcGlobal.png";
import pancakeSwap from "../images/pancakeSwap.png";
import astr from "../images/astr.png";
import bitmart from "../images/bitmart.png";
import or from "../images/option.png";

function Home() {
  const matches = useMediaQuery("(max-width:600px)");
  return (
    <Box
      sx={{
        background: `url(${bg})`,
        backgroundPosition: "cover",
        backgroundSize: "center center",
        backgroundRepeat: "no-repeat",
      }}
      zIndex="10"
      pb={{ xs: 7, sm: 25 }}
      pt={20}
    >
      <Container maxWidth="lg">
        <Grid
          container
          spacing={4}
          justifyContent={{ xs: "center", sm: "space-between" }}
          mt={4}
        >
          <Grid item xs={12} sm={12} md={6} display="flex" alignItems="center">
            <Box>
              <Box fontSize={{ xs: "34px", sm: "50px" }} fontWeight={700}>
                ADADAO STAKING
              </Box>
              <Box
                fontSize={{ xs: "12px", sm: "15px" }}
                fontWeight={700}
                my={3}
              >
                Adadao staking program rewards token holders with generous token
                rewards and special NFT prizes. Try out the calculator below to
                see how you could benefit. Or buy some $ADAO now, If you haven't
                already!
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={5}>
            <Box
              sx={{
                background:
                  "linear-gradient(180deg, rgba(238, 233, 233, 0.49) 0%, rgba(238, 233, 233, 0.15) 100%)",
                boxShadow: "0px 4px 9px #101023",
                borderRadius: "21px",
              }}
              px={{ xs: 3, sm: 5 }}
              py={4}
            >
              <Box
                color="primary.main"
                fontWeight={800}
                fontSize={{ xs: "25px", sm: "30px" }}
                textAlign="center"
              >
                Token sale is live
              </Box>
              <Box textAlign="center" mt={-1} ml={-5}>
                <img src={underline} alt="" />
              </Box>

              <Box
                color="primary.main"
                fontWeight={500}
                fontSize={{ xs: "12px", sm: "15px" }}
                textAlign="center"
                my={2}
              >
                Buy ADAO tokens
              </Box>

              <Box
                display="flex"
                justifyContent="space-around"
                alignItems="center"
                flexWrap="wrap"
                my={3}
              >
                <a
                  href="https://exchange.sundaeswap.finance/#/swap?swap_from=cardano.ada&swap_to=50f8758f5a5c9511a8d9b395e7d5df761bc7e46bcbcaf4d383bc1877.4144414f"
                  rel="noreferrer"
                  target="_blank"
                  style={{ margin: "0px 5px" }}
                >
                  <img
                    src={sundaeSwap}
                    width={matches ? "90px" : "130px"}
                    alt=""
                  />
                </a>
                <a
                  href="https://www.mexc.com/exchange/ADAO_USDT"
                  rel="noreferrer"
                  target="_blank"
                  style={{ margin: "0px 5px" }}
                >
                  <img
                    src={mexcGlobal}
                    width={matches ? "90px" : "130px"}
                    alt=""
                  />
                </a>
                <a
                  href="https://pancakeswap.finance/swap?outputCurrency=0x3b76374Cc2DfE28Cc373DcA6d5024791B2586335"
                  rel="noreferrer"
                  target="_blank"
                  style={{ margin: "0px 5px" }}
                >
                  <img
                    src={pancakeSwap}
                    width={matches ? "90px" : "130px"}
                    alt=""
                  />
                </a>
                <a
                  href="https://exchange.astroswap.app/swap?inputCurrency=0x141Ba6bECdA65b991F12e417f07636c656937E07"
                  rel="noreferrer"
                  target="_blank"
                  style={{ margin: "0px 5px" }}
                >
                  <img src={astr} width={matches ? "40px" : "40px"} alt="" />
                </a>
                <a
                  href="https://www.bitmart.com/trade/en?symbol=ADAO_USDT&layout=basic"
                  rel="noreferrer"
                  target="_blank"
                  style={{ margin: "0px 5px" }}
                >
                  <img
                    src={bitmart}
                    width={matches ? "90px" : "120px"}
                    alt=""
                  />
                </a>
              </Box>
              <Box display="flex" justifyContent="center" alignItems="center">
                <img src={or} width="100%" alt="" />
              </Box>
              <Box
                mt={3}
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <Link to="calculator">
                  <Button
                    variant="contained"
                    sx={{
                      background:
                        "linear-gradient(180deg, #0066FF 0%, #120A57 100%)",
                      boxShadow: "-1px 3px 3px rgba(1, 100, 246, 0.29)",
                      borderRadius: "10px",
                      height: matches ? "34.15px" : "44.15px",
                      width: "100%",
                      fontWeight: 700,
                      fontSize: matches ? "10px" : "15px",
                    }}
                  >
                    Stake $ADAO tokens to earn more
                  </Button>
                </Link>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default Home;
