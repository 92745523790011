import React, { useState, useContext, useEffect } from "react";
import { Box, Container, useMediaQuery, Grid, Hidden } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import logo from "../images/logo.png";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import { Paper } from "@mui/material";
import { Link, useLocation } from "react-router-dom";

import ConnectionModal from "./Modals/ConnectionModal";
import DisConnectModal from "./Modals/DisConnectModal";
import WrongNetworkModal from "./Modals/WrongNetworkModal";
import { useAccount } from "wagmi";
import { AppContext } from "../utils";

const useStyles = makeStyles({
	list: {
		width: 250,
		paddingTop: "70px",
	},
	fullList: {
		width: "auto",
		alignItems: "center",
	},
	paper: {
		background: "#000 !important",
		justifyContent: "space-between",
	},
	hover: {
		"&:hover": {
			color: "#FFB800",
		},
	},
	active: {
		color: "#0164F6",
		// textDecoration: "underline",
		borderBottom: "1px solid #0164F6",
	},
	colorChange: {
		backgroundColor: "#080523",
	},
});

export default function Header() {
	const loc = useLocation();
	const {
		connectModal,
		setConnectModal,
		disconnectModal,
		setDisconnectModal,
		toggleDisConnectModal,
		toggleConnectModal,
	} = useContext(AppContext);
	const classes = useStyles();
	const [state, setState] = useState(false);
	const matches = useMediaQuery("(max-width:960px)");

	const { address, isConnected } = useAccount();
	const [renderModal, setrenderModal] = useState(false);

	useEffect(() => {
		if (isConnected) {
			setTimeout(() => {
				setDisconnectModal(false);
				setrenderModal(true);
			}, 4000);
		} else {
			setConnectModal(false);
			setrenderModal(false);
		}
	}, [isConnected]);

	const toggleDrawer = (anchor, open) => (event) => {
		if (
			event &&
			event.type === "keydown" &&
			(event.key === "Tab" || event.key === "Shift")
		) {
			return;
		}
		setState({ ...state, [anchor]: open });
	};
	const list = (anchor) => (
		<div
			className={clsx(classes.list, {
				[classes.fullList]: anchor === "top" || anchor === "bottom",
			})}
			role="presentation"
			onClick={toggleDrawer(anchor, false)}
			onKeyDown={toggleDrawer(anchor, false)}
		>
			<Box mb={5} display="flex" justifyContent="center">
				{/* LOGO */}
				<img width="100px" src={logo} alt="" />
			</Box>
			<List>
				{["GitHub", "Whitepaper", "PitchDeck"].map((text, index) => (
					<ListItem
						button
						style={{
							justifyContent: "center",
							// borderBottom: "1px solid #bbb8b8",
						}}
						key={text}
					>
						<ListItemText
							style={{
								textTransform: "capitalize",
								textAlign: "center",
								textDecoration: "none",
								cursor: "pointer",
								color: "#ffffff",
								fontSize: "15px",
								fontWeight: 600,
							}}
							primary={text}
						/>
					</ListItem>
				))}
			</List>
			<Box mt={5} display="flex" justifyContent="center">
				{isConnected ? (
					<Box
						sx={{
							background:
								"linear-gradient(180deg, #5D065F 3.57%, #3003B1 96.35%, #140C36 100%)",
							cursor: "pointer",
							boxShadow: "1px -1px 16px 1px rgba(93, 6, 95, 0.8)",
						}}
						height="42px"
						fontWeight="600"
						borderRadius="8px"
						fontSize="15px"
						width="90%"
						display="flex"
						justifyContent="center"
						alignItems="center"
						color="#ffffff"
						onClick={toggleDisConnectModal()}
						style={{ zIndex: 1 }}
					>
						{address.slice(0, 6) + "..." + address.slice(-4)}
					</Box>
				) : (
					<Box
						zIndex={1}
						sx={{
							background:
								"linear-gradient(180deg, #5D065F 3.57%, #3003B1 96.35%, #140C36 100%)",
							cursor: "pointer",
							boxShadow: "1px -1px 16px 1px rgba(93, 6, 95, 0.8)",
						}}
						height="42px"
						fontWeight="600"
						borderRadius="8px"
						fontSize="15px"
						width="90%"
						color="#ffffff"
						display="flex"
						justifyContent="center"
						alignItems="center"
						onClick={toggleConnectModal()}
					>
						Connect
					</Box>
				)}
			</Box>
		</div>
	);

	const [colorChange, setColorchange] = useState(false);
	const changeNavbarColor = () => {
		if (window.scrollY >= 80) {
			setColorchange(true);
		} else {
			setColorchange(false);
		}
	};
	window.addEventListener("scroll", changeNavbarColor);
	return (
		<>
			<WrongNetworkModal />
			{renderModal ? (
				<DisConnectModal
					toggleDisConnectModal={toggleDisConnectModal}
					disconnectModal={disconnectModal}
				/>
			) : (
				<ConnectionModal
					toggleConnectModal={toggleConnectModal}
					connectModal={connectModal}
				/>
			)}
			<Box position="relative">
				<Box
					sx={{
						position: "fixed",
						zIndex: 50,
					}}
					width="100vw"
					className={colorChange ? classes.colorChange : null}
				>
					<Container maxWidth="lg">
						<Box
							padding="30px 0px"
							display="flex"
							justifyContent="space-between"
							alignItems="center"
						>
							<Box
								display="flex"
								justifyContent="space-between"
								alignItems="center"
								flexBasis="42%"
							>
								<Box>
									<Grid
										container
										direction="row"
										justifyContent="center"
										alignItems="center"
									>
										<Grid item>
											{/* <Box color="#fff">LOGO</Box> */}
											<img
												src={logo}
												width={matches ? "120px" : "135px"}
												alt=""
											/>
										</Grid>
									</Grid>
								</Box>
							</Box>

							<Box display={{ xs: "none", sm: "block" }}>
								<Hidden mdDown>
									<Box
										display="flex"
										justifyContent="space-evenly"
										alignItems="center"
									>
										<Link
											to="/"
											style={{
												textDecoration: "none",
												color: "#fff",
											}}
										>
											<Box
												fontWeight={600}
												fontSize="15px"
												mr={6}
												className={loc.pathname === "/" ? classes.active : null}
											>
												Home
											</Box>
										</Link>

										<a
											href="#github"
											target="_blank"
											style={{
												textDecoration: "none",
												color: "#fff",
											}}
										>
											<Box fontWeight={600} fontSize="15px" mr={6}>
												GitHub
											</Box>
										</a>

										<a
											href="#whitepaper"
											target="_blank"
											style={{
												textDecoration: "none",
												color: "#fff",
											}}
										>
											<Box
												fontWeight={600}
												fontSize="15px"
												mr={6}
												// className={
												//   active === "whitepaper" ? classes.active : null
												// }
												// onClick={() => seturl("whitepaper")}
											>
												Whitepaper
											</Box>
										</a>

										<a
											href="#pitchDeck"
											target="_blank"
											style={{
												textDecoration: "none",
												color: "#fff",
											}}
										>
											<Box
												fontWeight={600}
												fontSize="15px"
												mr={6}
												// className={active === "pitchDeck" ? classes.active : null}
												// onClick={() => seturl("pitchDeck")}
											>
												PitchDeck
											</Box>
										</a>

										{isConnected ? (
											<Box
												zIndex={1}
												sx={{
													background:
														"linear-gradient(345deg, #0066FF 0%, #120A57 100%)",
													boxShadow: "-1px 3px 3px rgba(1, 100, 246, 0.29)",
													cursor: "pointer",
												}}
												width="135px"
												height="42px"
												fontWeight="600"
												borderRadius="8px"
												fontSize="15px"
												display="flex"
												justifyContent="center"
												alignItems="center"
												color="#ffffff"
												onClick={toggleDisConnectModal()}
												style={{ zIndex: 1 }}
											>
												{address.slice(0, 6) + "..." + address.slice(-4)}
											</Box>
										) : (
											<Box
												zIndex={1}
												sx={{
													background:
														"linear-gradient(345deg, #0066FF 0%, #120A57 100%)",
													boxShadow: "-1px 3px 3px rgba(1, 100, 246, 0.29)",
													cursor: "pointer",
												}}
												width="135px"
												height="42px"
												fontWeight="600"
												borderRadius="8px"
												fontSize="15px"
												color="#ffffff"
												display="flex"
												justifyContent="center"
												alignItems="center"
												onClick={toggleConnectModal()}
											>
												Connect
											</Box>
										)}
									</Box>
								</Hidden>
							</Box>
							<Hidden mdUp>
								{["left"].map((anchor) => (
									<React.Fragment key={anchor}>
										<Button
											onClick={toggleDrawer(anchor, true)}
											style={{
												zIndex: 1,
												justifyContent: "end",
												width: "100%",
											}}
										>
											<MenuIcon
												style={{
													fontSize: "38px",
													cursor: "pointer",
													color: "#fff",
												}}
											></MenuIcon>
										</Button>
										<Paper style={{ background: "#1C0D38" }}>
											<SwipeableDrawer
												classes={{ paper: classes.paper }}
												anchor={anchor}
												open={state[anchor]}
												onClose={toggleDrawer(anchor, false)}
												onOpen={toggleDrawer(anchor, true)}
											>
												{list(anchor)}
											</SwipeableDrawer>
										</Paper>
									</React.Fragment>
								))}
							</Hidden>
						</Box>
					</Container>
				</Box>
			</Box>
		</>
	);
}
