import { createTheme, responsiveFontSizes } from "@mui/material/styles";

let theme = createTheme({
	palette: {
		primary: {
			main: "#120A57",
			contrastText: "#fff",
		},
		secondary: {
			main: "#0164F6",
			contrastText: "#fff",
		},
		text: {
			primary: "#fff",
			secondary: "#120A57",
		},
		background: {
			hard: "#000",
			medium: "#151515",
			light: "#212121",
			borderLight: "#424242",
			shadow: "#000",
			greenColor: "rgba(27,200,112,1)",
			lightGreen: "rgba(27,200,112,0.2)",
			redColor: "rgba(255,0,51,1)",
			lightRed: "rgba(255,0,51,0.2)",
		},
	},
});

theme.overrides = {
	MuiCssBaseline: {
		"@global": {
			body: {
				fontFamily: "Lato",
				backgroundColor: "#0164F6 !important",
				color: "#ffffff",
			},
			".img-fluid": {
				maxWidth: "100%",
				height: "auto",
			},
		},
	},
};

theme = responsiveFontSizes(theme);

export default theme;
