import React, { useEffect, useState, useContext } from "react";
import "./App.css";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import { formatUnits } from "@ethersproject/units";

import Header from "./components/Header";
import Home from "./components/Home";
import Stake from "./components/Stake";
import FAQs from "./components/FAQs";
import Footer from "./components/Footer";
import Details from "./components/Details";
import { AppContext } from "./utils";
import { useStakingContract, useTokenContract } from "./ConnectivityAss/hooks";
import NetworkChange from "./networkSwitch";
import { useAccount, useNetwork, useSwitchNetwork } from "wagmi";

function App() {
	const { signer } = useContext(AppContext);
	const [stakeDetails, setstakeDetails] = useState([]);
	const StakingContract = useStakingContract(signer);

	const tokenContract = useTokenContract(signer);
	const [staked, setstaked] = useState(0);
	const [Supply, setSupply] = useState(0);
	const [unstaked, setunstaked] = useState(0);
	const [totalUnstaked, settotalUnstaked] = useState(0);
	const [withdrwan, setwithdrwan] = useState(0);
	const [reward, setreward] = useState(0);
	const [currentStaked, setcurrentStaked] = useState(0);
	const [earned, setearned] = useState(0);
	const [balance, setbalance] = useState(0);
	const [open, setOpen] = useState(false);
	const { isConnected, address: account } = useAccount();
	const { chain, chains } = useNetwork();

	const init = async () => {
		try {
			const dec = +(await tokenContract.decimals());
			const supply = await tokenContract.totalSupply();
			setSupply(formatUnits(supply, dec));
			const {
				totalStakedTokenUser,
				totalWithdrawanTokenUser,
				totalUnStakedTokenUser,
				totalClaimedRewardTokenUser,
			} = await StakingContract.Stakers(account);
			setstaked(formatUnits(totalStakedTokenUser, dec));
			settotalUnstaked(formatUnits(totalUnStakedTokenUser, dec));
			setwithdrwan(formatUnits(totalWithdrawanTokenUser, dec));
			setreward(formatUnits(totalClaimedRewardTokenUser, dec));
			const result = +totalWithdrawanTokenUser + +totalUnStakedTokenUser;
			setunstaked(
				formatUnits(
					result.toLocaleString("fullwide", {
						useGrouping: false,
					}),
					dec
				)
			);
			const { stakeCount } = await StakingContract.Stakers(account);
			const balance = await tokenContract.balanceOf(account);

			setbalance(
				formatUnits(balance, dec).toLocaleString({
					useGrouping: false,
				})
				// formatUnits(balance, dec)
			);

			if (+stakeCount > 0) {
				let arr = [];
				let currentstaked = 0;
				for (let i = 0; i < +stakeCount; i++) {
					const { amount, reward, withdrawan, unstaked, withdrawtime, plan } =
						await StakingContract.stakersRecord(account, i.toString());

					const obj = {
						time: +withdrawtime,
						amount: +formatUnits(amount, dec),
						reward: +formatUnits(reward, dec),
						withdrawan: withdrawan,
						unstaked: unstaked,
						plan: +plan,
					};
					if (!unstaked && !withdrawan) {
						currentstaked += +formatUnits(amount, dec);
					}

					arr.push(obj);
				}
				setcurrentStaked(currentstaked);
				setstakeDetails([...arr]);
			}
		} catch (error) {
			console.log(error);
		}
	};
	useEffect(() => {
		if (StakingContract && account && tokenContract) {
			init();
			// startTime();
		}
	}, [account]);

	useEffect(() => {
		if (isConnected) {
			if (chains.find((chains) => chains?.id === chain?.id)) setOpen(false);
			else setOpen(true);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [chain]);

	return (
		<BrowserRouter>
			<NetworkChange open={open} setOpen={setOpen} />
			<Header />
			<Routes>
				<Route
					exact
					path="/"
					element={
						<>
							<Home />
							<Stake
								Supply={Supply}
								unstaked={totalUnstaked}
								staked={staked}
								balance={balance}
								init={init}
							/>
							<Details stakeDetails={stakeDetails} init={init} />
							{/* <FAQs /> */}
							<Footer />
						</>
					}
				/>
			</Routes>
		</BrowserRouter>
	);
}

export default App;
