import React from "react";
import logo from "../images/logo.png";
import twitter from "../images/twitter.png";
import telegram from "../images/telegram.png";
import end from "../images/medium.png";
import { Box } from "@mui/system";
import { Container } from "@mui/material";
import bg from "../images/footer-bg.png";

function Footer() {
  return (
    <Box
      sx={{
        background: `url(${bg})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "100% 100%",
        backgroundPosition: "center",
      }}
      mt={6}
      py={2}
    >
      <Container>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          py={5}
        >
          <Box>
            <img src={logo} alt="" />
          </Box>

          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <a
              href="https://twitter.com/adadao_org"
              rel="noreferrer"
              target="_blank"
              style={{ margin: "0px 5px", color: "white" }}
            >
              <img src={twitter} width="33.81px" height="33.81px" alt="" />
            </a>
            <a
              href="https://t.me/adadao_org"
              rel="noreferrer"
              target="_blank"
              style={{ margin: "0px 5px", color: "white" }}
            >
              <img src={telegram} width="29.81px" height="27.81px" alt="" />
            </a>
            <a
              href="https://adadao.medium.com/"
              rel="noreferrer"
              target="_blank"
              style={{ margin: "0px 5px", color: "white" }}
            >
              <img src={end} width="37px" height="19.81px" alt="" />
            </a>
          </Box>
        </Box>
      </Container>
    </Box>
  );
}

export default Footer;
