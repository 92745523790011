import React, { useEffect, useState } from "react";
import { useTheme } from "@mui/material/styles";
import {
	Box,
	Button,
	Dialog,
	DialogContent,
	Slide,
	Stack,
} from "@mui/material";

import { useAccount, useNetwork, useSwitchNetwork } from "wagmi";

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});
function WrongNetworkModal() {
	const [open, setOpen] = useState(false);
	const theme = useTheme();
	const { switchNetwork } = useSwitchNetwork();
	const { isConnected } = useAccount();
	const { chain, chains } = useNetwork();

	useEffect(() => {
		if (isConnected) {
			if (chain?.id === 56) setOpen(false);
			else setOpen(true);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [chain]);

	const networkHandler = async (x) => {
		switchNetwork?.(x.id);
	};
	return (
		<>
			<Dialog
				open={open}
				keepMounted
				TransitionComponent={Transition}
				aria-labelledby="alert-dialog-slide-title"
				aria-describedby="alert-dialog-slide-description"
				sx={{
					".MuiDialog-paperScrollPaper": {
						borderRadius: "20px",
						background: theme.palette.background.medium,
					},
				}}
			>
				<Stack
					direction="row"
					alignItems="center"
					px={4}
					py={3}
					sx={{
						borderBottom: `1px solid ${theme.palette.background.borderLight}`,
					}}
				>
					<Box fontSize="22px" fontWeight={500}>
						Change Network
					</Box>
				</Stack>
				<DialogContent>
					<Box fontSize="18px" fontWeight={500} textAlign="center">
						You are on the wrong network, please change to the following network
						and try again.
					</Box>
					<Stack
						gap={1}
						direction="row"
						flexWrap="wrap"
						justifyContent="center"
						align="center"
						my={2}
					>
						{isConnected && (
							<Button
								variant="gradient"
								onClick={() => {
									networkHandler(chains[1]);
								}}
							>
								{chains[1].name}
							</Button>
						)}
					</Stack>
				</DialogContent>
			</Dialog>
		</>
	);
}

export default WrongNetworkModal;
